import logo from "../../assets/icons/white-logo.svg";

export default function Footer() {
  return (
    <>
      <div className="bg-[#111827] lg:max-w-5xl max-w-xs sm:max-w-md mx-auto w-full rounded-3xl lg:p-8 p-4">
        <div className="flex flex-col sm:flex-row items-center w-full justify-between gap-10">
          <div className="flex gap-2 lg:items-start items-center flex-col order-first">
            <img
              src={logo}
              alt="مؤسسة عبدالرحمن الشيخ خليل لتقنية المعلومات"
              className="w-9 h-auto"
            />
            <p className="text-white text-sm">تواصل معنا:</p>
            <a
              dir="ltr"
              href="tel:+966583331713"
              className="text-white text-right font-light text-sm"
            >
              +966583331713
            </a>
            <a
              dir="ltr"
              href="mailto:hi@askhalil.io"
              className="text-white text-right font-light text-sm"
            >
              hi@askhalil.io
            </a>
          </div>

          <p className="text-sm text-white mt-auto lg:order-2 text-center order-last">
            جميع الحقوق محفوظة لمؤسسة عبدالرحمن الشيخ خليل لتقنية المعلومات{" "}
            {new Date().getFullYear()}
          </p>
          <div className="flex gap-2 flex-col lg:items-start items-center lg:order-last order-2">
            <p className="text-white text-sm">السجل التجاري:</p>
            <p className="text-white text-sm font-light">1010987407</p>
            <p className="text-white text-sm">الرقم الضريبي:</p>
            <p className="text-white text-sm font-light">311340237600003</p>
          </div>
        </div>
      </div>
    </>
  );
}
